<template>
  <div>
    <!-- 头部标题 -->
    <el-header>
      <div class="title">预约管理</div>
    </el-header>

    <!-- 主内容区 -->
    <el-main>
      <el-table :data="bookingList" stripe border>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="bookTime" label="预约时间"></el-table-column>
        <el-table-column prop="bookSubject" label="预约主题"></el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="handleDelete(scope.row)">办结</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </div>
</template>

<script>
import { deleteBooking, getBookingList } from '@/api/booking'

export default {
  data() {
    return {
      bookingList: []
    };
  },
  created() {
    this.fetchBookingList();
  },
  methods: {
    fetchBookingList() {
      getBookingList().then(res => {
        if (res.data) {
          this.bookingList = res.data;
        }
      }).catch(error => {
        console.error(error);
      });
    },
    handleDelete(row) {
      this.$confirm('确认删除该预约信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBooking(row).then(() => {
          this.$message.success('删除成功');
          // 从列表中移除被删除的预约信息
          const index = this.bookingList.findIndex(item => item.id === row.id);
          if (index !== -1) {
            this.bookingList.splice(index, 1);
          }
        }).catch(error => {
          console.error(error);
          this.$message.error('删除失败');
        });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #409EFF;
  margin-left: 20px;
  margin-top: 10px;
}

.el-main {
  padding: 20px;
}
</style>
