import request from '@/utils/request'

// 用户主页
export function getBookingList() {
    return request({
      url: '/book/list',
      method: 'get'
    })
  }

  export function deleteBooking(book) {
    return request({
      url: '/book/del',
      method: 'post',
      data:book
    })
  }